<template>
  <DialogBase
    @closeDialog="(val) => closeDialog(val)"
    :dialogModel="dialogModel"
    :dialogWidth="900"
    scrollable
  >
    <template v-slot:title>Landing Page Management</template>

    <v-card elevation="0" class="ma-0 pa-0">
      <v-card-text class="ma-0 pa-0">
        <v-row class="my-6">
          <v-col cols="4">
            <v-card
              class="fill-height transparent-1"
              @click="showCreateUpdateLandingPageDialog()"
            >
              <v-card-title>
                <v-spacer></v-spacer>
                Add New Landing Page
                <v-spacer></v-spacer>
              </v-card-title>
              <v-card-text class="text-center">
                <v-icon color="primary" large> mdi-plus-circle-outline </v-icon>
              </v-card-text>
            </v-card>
          </v-col>
          <template v-for="(landingPage, index) in landingPages">
            <v-col cols="4" :key="index">
              <v-card
                class="fill-height transparent-1"
                @click="showCreateUpdateLandingPageDialog(landingPage)"
              >
                <v-card-title>
                  <v-spacer></v-spacer>
                  {{ landingPage.company_name }}
                  <v-spacer></v-spacer>
                </v-card-title>
                <v-card-text :key="index" class="text-center my-0 py-1">
                  {{ landingPage.domain }}
                </v-card-text>
              </v-card>
            </v-col>
          </template>
        </v-row>
      </v-card-text>
    </v-card>

    <template v-slot:footer>
      <v-btn color="accent" outlined block @click="closeDialog()">Close</v-btn>
    </template>
    <template v-if="createUpdateLandingPageDialog">
      <createUpdateLandingPageDialog
        :dialogModel="createUpdateLandingPageDialog"
        :selectedModel="selectedLandingPage"
        @closeDialog="closeCreateUpdateLandingPageDialog()"
        @created="closeCreateUpdateLandingPageDialog()"
        @refresh="getLandingPages()"
      />
    </template>
  </DialogBase>
</template>

<script>
import DialogBase from "@/components/dialogs/Base";
import createUpdateLandingPageDialog from "@/components/dialogs/createUpdateLandingPageDialog";
export default {
  components: {
    DialogBase,
    createUpdateLandingPageDialog,
  },
  props: {
    dialogModel: { required: true, type: Boolean },
    selectedModel: { required: true, default: null },
  },
  data() {
    return {
      createUpdateLandingPageDialog: false,
      selectedLandingPage: null,
      landingPages: [],
    };
  },
  mounted() {
    this.getLandingPages();
  },
  computed: {},
  methods: {
    closeDialog(val = false) {
      this.$emit("closeDialog", val);
    },
    showCreateUpdateLandingPageDialog(selectedLandingPage = null) {
      this.selectedLandingPage = selectedLandingPage;
      this.createUpdateLandingPageDialog = true;
    },
    closeCreateUpdateLandingPageDialog() {
      this.selectedLandingPage = null;
      this.createUpdateLandingPageDialog = false;
    },
    async getLandingPages() {
      let data = {};
      return await this.post(
        this.baseUrl + `/agent-portal/get-landing-page-list`,
        data,
        false
      ).then((data) => {
        if (data == "undefined") return;
        if (data.code == 200) {
          this.landingPages = data?.data?.landing_pages;
          // console.log(JSON.stringify(this.landingPages));
        }
      });
    },
  },
};
</script>
